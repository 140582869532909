import React from "react";
import { CarruselArrowRight } from "../../images/icons";
import { LinkIcon } from "../shared/links";
export const ParticipatingArtist = ({ artist }) => {
  return (
    <LinkIcon>
      <div className="flex items-center mb-sm">
        <CarruselArrowRight classes={`h-[0.75rem] mr-md `} />
        <div className="capitalize">
          {artist.firstName} {artist.lastName}
        </div>
      </div>
    </LinkIcon>
  );
};
