// This is the template for a single sign
import * as React from "react";
import { graphql, Link } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import Layout from "../components/shared/layout";
import { Seo } from "../components/shared/seo";
import { LinkType } from "../components/shared/links";
import { Carousel } from "../components/shared/carousel";
import { BackBtn } from "../components/shared/backBtn";
import ModalGallery from "../components/shared/modalGallery";
import { PlayIcon, VrIcon } from "../images/icons";
import { Capsule } from "../components/shared/links";
import { ParticipatingArtist } from "../components/projects/artistParticipating";
import VrModal from "../components/shared/modaVr";

// ------------> PROJECT (single) page <------------ //
const ProjectPageTemplate = (props) => {
  // --->  Data <--- //
  // const project = props.data.strapiProject;
  const {
    artists,
    heroImage,
    projectType,
    title,
    year,
    contentType,
    externalArtist,
  } = props.data.strapiProject;
  // Combine Artists with External Artists
  const allArtists = [...artists, ...externalArtist];
  const allArtistsSort = allArtists.sort((a, b) =>
    a.lastName.localeCompare(b.lastName)
  );
  // Next botton
  // const nextProject = props.pageContext.nextProjectSlug;
  // Hero Image
  const projectHerolImage = getImage(
    heroImage.imageFile.localFile.childImageSharp
  );
  // ---> Modal Setup <--- //
  const [showModal, setShowModal] = React.useState(false);
  const handleOnClose = () => setShowModal(false);
  const [showVrModal, setShowVrModal] = React.useState(false);
  const handleOnCloseVrModal = () => setShowVrModal(false);

  return (
    <Layout
      location={props.location}
      bodyClass={"preimeryBg"}
      // Added
      navbarHeight={"xs:mt-[4.5rem] md:mt-[4.5rem]"}
    >
      {/* TOP - HERO IMAGE  */}
      <GatsbyImage
        image={projectHerolImage}
        alt={""}
        className={
          "xs:h-[35vh] md:h-[50vh] -mx-base sm:-mx-md  md:-mx-md lg:-mx-lg px-base sm:px-md md:px-md lg:px-lg"
        }
      />
      {/* --- TOP - HERO CAPTION --- */}
      <div className="text-sm flex justify-center mt-sm">
        {heroImage.imageCaption}
      </div>
      {/* --- PROJECT TYPE --- */}
      <LinkType
        slug={"project-type"}
        type={projectType.projectType}
        isMedium={false}
        classes={"my-xl"}
      />
      {/* --- TITILE: IS EXHIBITION? --- */}
      <div
        className={`  ${
          contentType[0].strapi_component === "exhibition.exhibition"
            ? " grid xs:grid-cols-1 md:grid-cols-2 gap-16"
            : ""
        }`}
      >
        {/* --- IS EXHIBITION --- */}
        {contentType[0].strapi_component === "exhibition.exhibition" ? (
          <>
            <div id={"Project Exhibition Top"}>
              {/* ---  EXHIBITION TITLE --- */}
              <div className="text-lg">{title}</div>

              {/* --- EXHIBITION CURATORS & YEAR --- */}
              <div className="mt-lg">
                {/* ---  CURATORS MAP --- */}
                {contentType[0].curatorName.map((curator, index) => {
                  return (
                    <span key={index} className="">
                      {curator.firstName} {curator.lastName},{" "}
                    </span>
                  );
                })}
                {year}
              </div>
            </div>

            {/* --- EXHIBITION VR && GALLERY --- */}

            <div>
              {contentType[0].imageGallery != null &&
              contentType[0].showGallery ? (
                <button
                  className={"flex items-center"}
                  onClick={() => setShowVrModal(!showVrModal)}
                >
                  <VrIcon classes={"h-[1.7rem] w-[3rem] mr-sm"} />
                  <Capsule title={"Virtual Tour"} />
                </button>
              ) : null}
              {contentType[0].showVR ? (
                <button
                  className={"flex items-center mt-md"}
                  onClick={() => setShowModal(!showModal)}
                >
                  <PlayIcon
                    classes={"h-[1.7rem] w-[3rem] mr-sm"}
                    stroke={"1px"}
                  />
                  <Capsule title={"Slide Show"} />
                </button>
              ) : null}
              <ModalGallery visible={showModal} onClose={handleOnClose}>
                <Carousel
                  dataImages={contentType[0].imageGallery}
                  height={"h-[33vh] md:h-[33vh] lg:h-[50vh]"}
                  modal={true}
                />
              </ModalGallery>
              <VrModal
                visible={showVrModal}
                onClose={handleOnCloseVrModal}
                link={contentType[0].virtualGalleryLink}
                title={title}
              />
            </div>
          </>
        ) : (
          /* --- IS OTHER PROJECT TYPE? ---  */
          <div id={"Project Other Top"} className={"mt-xl"}>
            <div className="text-lg">{title}</div>
            <div className="mt-md">{year}</div>
          </div>
        )}
      </div>

      {/* ---  EXHIBITION TEXT --- */}
      {contentType[0].strapi_component === "exhibition.exhibition" ? (
        <div
          className="mt-xl text-box"
          dangerouslySetInnerHTML={{ __html: contentType[0].text.data.text }}
        />
      ) : (
        /* --------- IS OTHER? --------- */
        contentType[0].strapi_component === "exhibition.other" && (
          <div>
            {/* OTHER TEXT  */}
            <div
              className="my-xl text-box"
              dangerouslySetInnerHTML={{
                __html: contentType[0].textWYSIWYG.data.textWYSIWYG,
              }}
            />
            {/* --- OTHER CAROUSEL --- */}
            <Carousel dataImages={contentType[0].images} />
          </div>
        )
      )}

      {/* ---  ARTISTS --- */}
      <div className={"mt-xl"}>Paticipating Artists:</div>
      <div className="grid xs:grid-col-1 xs:grid-flow-row md:grid-rows-2 md:grid-flow-col mt-md xl:w-2/3">
        {allArtistsSort.map((artist, index) => {
          return artist.slug ? (
            <Link to={`/artist/${artist.slug}`} key={index}>
              <ParticipatingArtist artist={artist} />
            </Link>
          ) : (
            <a
              href={artist.websiteLink}
              target={"_blank"}
              key={index}
              rel="noreferrer"
            >
              <ParticipatingArtist artist={artist} />
            </a>
          );
        })}
      </div>
      {/* ---> BACK BUTTON <--- */}
      <BackBtn classes={"my-xl"} />
    </Layout>
  );
};

export const query = graphql`
  query ($title: String) {
    site {
      siteMetadata {
        title
      }
    }
    strapiProject(title: { eq: $title }) {
      artists {
        firstName
        lastName
        slug
      }
      featured
      externalArtist {
        lastName
        firstName
        websiteLink
      }
      heroImage {
        imageCaption
        imageAlt
        imageFile {
          localFile {
            childImageSharp {
              gatsbyImageData(
                placeholder: BLURRED
                formats: NO_CHANGE
                width: 700
                #height: 100
                quality: 100
                layout: FULL_WIDTH
                transformOptions: { fit: COVER }
                #layout: CONSTRAINED
              )
            }
          }
        }
      }
      projectType {
        projectType
      }
      title
      year
      contentType {
        ... on STRAPI__COMPONENT_EXHIBITION_OTHER {
          strapi_component
          images {
            imageCaption
            imageAlt
            imageFile {
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    placeholder: BLURRED
                    formats: NO_CHANGE
                    width: 700
                    #height: 100
                    quality: 100
                    #layout: FULL_WIDTH
                    #transformOptions: { fit: CONTAIN }
                    layout: CONSTRAINED
                  )
                }
              }
            }
          }
          textWYSIWYG {
            data {
              textWYSIWYG
            }
          }
        }
        ... on STRAPI__COMPONENT_EXHIBITION_EXHIBITION {
          virtualGalleryLink
          showVR
          showGallery
          strapi_component
          curatorName {
            firstName
            lastName
          }
          text {
            data {
              text
            }
          }
          imageGallery {
            imageAlt
            imageCaption
            imageFile {
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    placeholder: BLURRED
                    formats: NO_CHANGE
                    width: 700
                    #height: 100
                    quality: 100
                    #layout: FULL_WIDTH
                    transformOptions: { fit: COVER }
                    layout: CONSTRAINED
                  )
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default ProjectPageTemplate;

// *---> SEO
export const Head = () => (
  <Seo
    title={"Artists"}
    description={"IlCollection Artists"}
    keywords={"IlCollection Artists, Art"}
  />
);
