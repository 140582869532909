import * as React from "react";
import useKeyPress from "../../utils/useKeyPress";
import { CloseIcon, ArrowRegIcon, VrIcon } from "../../images/icons";
import { motion, AnimatePresence } from "framer-motion";
import { LinkIcon } from "./links";
export default function VrModal({ visible, onClose, link, title }) {
  // Esc close
  function handleKeyDown(e) {
    if (e.keyCode === 13) {
      onClose();
    }
  }
  const pressed = useKeyPress("Escape");
  pressed && onClose();

  return (
    // ---> Modal <--- //
    <AnimatePresence>
      {visible ? (
        <motion.div
          initial={{
            opacity: 0,
          }}
          animate={{
            opacity: 1,
            transition: {
              ease: "easeOut",
              duration: 0.15,
              delay: 0,
            },
          }}
          exit={{
            opacity: 0,
            transition: {
              ease: "easeIn",
              duration: 0.15,
              delay: 0,
            },
          }}
        >
          <div
            id={"signalModal"}
            onClick={onClose}
            role={"button"}
            tabIndex={0}
            onKeyDown={handleKeyDown}
            className={
              "bg-black bg-opacity-50 flex fixed z-50 inset-0 justify-center items-center backdrop-blur-sm pointer-events"
            }
          >
            {/* ---> Modal Container <---  */}
            <motion.div
              className="bg-white cursor-default p-8 w-4/5 h-4/5 md:w-3/4 md:h-3/4 overflow-y-none hideScroll flex flex-col"
              initial={{
                opacity: 0,
                scale: 0.95,
              }}
              animate={{
                opacity: 1,
                scale: 1,
                transition: {
                  ease: "easeOut",
                  duration: 0.15,
                  delay: 0.15,
                },
              }}
              exit={{
                opacity: 0,
                scale: 0.75,
                transition: {
                  ease: "easeIn",
                  duration: 0.15,
                  delay: 0,
                },
              }}
            >
              {/* ---> Modal Close <---  */}
              <div className=" top-0 sticky">
                <div className="flex justify-end">
                  <button onClick={onClose}>
                    <CloseIcon classes={"text-black"} w={20} />
                  </button>
                </div>
              </div>

              {/* ---> Modal Content <---  */}
              <div className="text-black flex flex-col text-base  h-full overflow-y-none mt-5">
                <p className="mb-[1.3em]">
                  You are about to visit "{title}" exhibition space in VR at
                  artsteps.com <VrIcon classes={"h-[2rem]"} />
                </p>
                <LinkIcon>
                  <a
                    href={`${link}`}
                    target="_blank"
                    rel="noreferrer"
                    className={`flex flex-row items-center`}
                  >
                    <div className="mr-md ">Continue</div>
                    <ArrowRegIcon classes={`h-[1rem] mr-md xs:mt-1 md:mt-1`} />
                  </a>
                </LinkIcon>
                {/* Modal */}
              </div>
            </motion.div>
          </div>
        </motion.div>
      ) : null}
    </AnimatePresence>
  );
}
