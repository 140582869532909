import * as React from "react";
import useKeyPress from "../../utils/useKeyPress";
import { CloseIcon } from "../../images/icons";
import { motion, AnimatePresence } from "framer-motion";
import { layoutPadding, layoutPaddingXY } from "./styles";

export default function ModalGallery({
  visible,
  onClose,
  modalData,
  children,
}) {
  // Esc close
  function handleKeyDown(e) {
    if (e.keyCode === 13) {
      onClose();
    }
  }
  const pressed = useKeyPress("Escape");
  pressed && onClose();

  //   if (!visible) return null;

  return (
    // ---> Modal <--- //

    <AnimatePresence>
      {visible ? (
        <motion.div
          className={
            "bg-black bg-opacity-100 flex fixed justify-center items-center z-[50] inset-0  pointer-events-auto"
          }
          onKeyDown={handleKeyDown}
          initial={{
            opacity: 0,
          }}
          animate={{
            opacity: 1,
            transition: {
              ease: "easeOut",
              duration: 0.15,
              delay: 0,
            },
          }}
          exit={{
            opacity: 0,
            transition: {
              ease: "easeIn",
              duration: 0.15,
              delay: 0,
            },
          }}
        >
          {/* ---> Modal Close <---  */}
          <div className={`${layoutPaddingXY} fixed`}>
            <div className="flex justify-end">
              <button onClick={onClose}>
                <CloseIcon classes={"text-white h-[1rem]"} />
              </button>
            </div>
          </div>
          {/* ---> Modal Container <---  */}
          <motion.div
            className="cursor-default w-full h-auto flex flex-col"
            initial={{
              opacity: 0,
              scale: 0.95,
            }}
            animate={{
              opacity: 1,
              scale: 1,
              transition: {
                ease: "easeOut",
                duration: 0.15,
                delay: 0.15,
              },
            }}
            exit={{
              opacity: 0,
              scale: 0.75,
              transition: {
                ease: "easeIn",
                duration: 0.15,
                delay: 0,
              },
            }}
          >
            {/* ---> Modal Content <---  */}
            {/* <div className="text-black flex flex-col text-base  h-full overflow-y-none mt-5"> */}
            <div
              className={`relative overflow-y-none select-none ${layoutPadding}`}
            >
              {children}
            </div>
          </motion.div>
        </motion.div>
      ) : null}
    </AnimatePresence>
  );
}
